// 📄 ReportDetails.js
import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import {
    FormGroup,
    Label,
    Input,
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Row,
    Col
} from 'reactstrap';

import ReactJson from 'react-json-view';

import reportService from 'services/report.service';
import membersService from 'services/members.service';
import authService from 'services/auth.service';

const jwt = require('jsonwebtoken');

function ReportDetails(props) {
    const data = props.data || {};
    const onBack = props.onBack || (() => { });

    const [id, setId] = useState(data.id || '');
    const [reportBy, setReportBy] = useState(data.reportBy || '');
    const [childIndex, setChildIndex] = useState(data.childIndex || '');
    const [toolName, setToolName] = useState(data.toolName || '');
    const [errorMessage, setErrorMessage] = useState(data.errorMessage || '');
    const [stackTrace, setStackTrace] = useState(data.stackTrace || '');
    const [status, setStatus] = useState(data.status || 'Pending');
    const [adminResponse, setAdminResponse] = useState(data.adminResponse || '');
    const [attachment, setAttachment] = useState(data.attachment || '');
    const [errorData, setErrorData] = useState(data.errorData || '');
    const [errorDataShort, setErrorDataShort] = useState(data.errorDataShort || '');
    const [members, setMembers] = useState([]);
    const [selectedMember, setSelectedMember] = useState(data.handledBy || '');;
    const [note, setNote] = useState('');
    const [imageURLs, setImageURLs] = useState([]);

    useEffect(() => {
        fetchReportDetails();
        fetchMembers();
    }, []);

    /** 📥 Tải chi tiết báo lỗi */
    async function fetchReportDetails() {
        const result = await reportService.getReportDetails(id);
        const report = result.data;
        setAdminResponse(report.adminResponse);
        setAttachment(report.attachment);
        setErrorData(report.errorData);
        setErrorDataShort(report.errorDataShort);
        setNote(report.note);

        const urls = await Promise.all(
            report.imageAttachments.map(async (filename) => {
                const url = await reportService.downloadImage(filename.split('/').pop());
                return url;
            })
        );

        setImageURLs(urls);
    }

    /** 📥 Tải tệp đính kèm */
    function downloadAttachment() {
        if (!attachment) {
            Swal.fire('Thông báo', 'Không có tệp đính kèm.', 'info');
            return;
        }
        reportService.downloadAttachment(attachment)
            .catch(() => {
                Swal.fire('Lỗi!', 'Không thể tải tệp đính kèm.', 'error');
            });
    }

    /** 📥 Lấy danh sách thành viên */
    function fetchMembers() {
        reportService.getAllUsers()
            .then(response => {
                setMembers(response);

                if (selectedMember) {
                    // Nếu đã chọn thành viên trước đó, chọn lại thành viên đó
                    var selected = response.find(member => member.username === selectedMember.username);
                    setSelectedMember(selected);
                } else {
                    // Nếu chưa chọn thành viên, chọn thành viên hiện tại
                    const user = authService.getCurrentUserData();
                    const decoded = jwt.decode(user.token, { complete: true });
                    var selected = response.find(member => member.username === decoded.payload.username);
                    setSelectedMember(selected);
                }
            })
            .catch(error => {
                console.error("Lỗi khi lấy danh sách thành viên:", error);
            });
    }

    /** 📤 Gửi phản hồi */
    function updateReport() {
        const data = {
            handlerByUsername: selectedMember.username,
            status: status,
            adminResponse: adminResponse
        };

        reportService.updateUserReport(id, data)
            .then(() => {
                Swal.fire('Thành công!', 'Cập nhập báo lỗi thành công.', 'success');
                onBack();
            })
            .catch(() => {
                Swal.fire('Lỗi!', 'Không thể cập nhập báo lỗi.', 'error');
            });
    }

    // Xóa báo lỗi
    function deleteReport() {
        Swal.fire({
            title: 'Xác nhận xóa báo lỗi?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Xóa',
            cancelButtonText: 'Hủy',
        }).then((result) => {
            if (result.isConfirmed) {
                reportService.deleteReport(id).then(() => {
                    Swal.fire('Thành công!', 'Xóa báo lỗi thành công', 'success');
                    onBack();
                }).catch((error) => {
                    Swal.fire('Lỗi!', `Không thể xóa báo lỗi <br><br> ${error}`, 'error');
                });
            }
        });
    }


    // Hàm giải mã Base64 an toàn với UTF-8
    function decodeBase64(value) {
        try {
            if (typeof value === 'string' && /^[A-Za-z0-9+/=]+$/.test(value)) {
                const decoded = atob(value);
                const utf8Decoded = decodeURIComponent(escape(decoded)); // Chuyển sang UTF-8
                // Kiểm tra nếu kết quả có thể parse thành JSON
                try {
                    return JSON.parse(utf8Decoded);
                } catch {
                    return utf8Decoded;
                }
            }
        } catch (e) {
        }

        return value; // Nếu thất bại, trả về giá trị gốc
    }

    // Hàm đệ quy để giải mã toàn bộ object
    function decodeBase64Recursive(data) {
        if (Array.isArray(data)) {
            return data.map(decodeBase64Recursive);
        } else if (typeof data === 'object' && data !== null) {
            return Object.fromEntries(
                Object.entries(data).map(([key, value]) => [key, decodeBase64Recursive(decodeBase64(value))])
            );
        }
        return decodeBase64(data);
    }

    return (
        <div className="content">
            <Row>
                {/* Chi tiết báo lỗi (9 cột) */}
                <Col lg="9" md="8">
                    <Card style={{ backgroundColor: '#fff', borderRadius: '10px', padding: '15px', margin: '20px auto' }}>
                        <CardHeader>
                            <Label style={{ fontSize: '24px', color: 'DimGray', fontWeight: 'bold' }}>Chi tiết báo lỗi</Label>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col md={4}>
                                    <FormGroup>
                                        <Label>Username</Label>
                                        <Input value={`${reportBy.fullname} (${reportBy.username})`} disabled />
                                    </FormGroup>
                                </Col>
                                <Col md={4}>
                                    <FormGroup>
                                        <Label>Child Index</Label>
                                        <Input value={reportBy.childIndex} disabled />
                                    </FormGroup>
                                </Col>
                                <Col md={4}>
                                    <FormGroup>
                                        <Label>Tool Name</Label>
                                        <Input value={toolName} disabled />
                                    </FormGroup>
                                </Col>
                            </Row>

                            <FormGroup style={{ marginTop: '5px' }}>
                                <Label>Stack Trace</Label>
                                <Input value={stackTrace} disabled />
                            </FormGroup>

                            <FormGroup style={{ marginTop: '15px' }}>
                                <Label>Data Short</Label>
                                <Input type="textarea" style={{ minHeight: '250px' }} value={errorDataShort} />
                            </FormGroup>

                            <FormGroup style={{ marginTop: '15px' }}>
                                <Label>Data</Label>
                                <div style={{
                                    backgroundColor: '#f8f9fa',
                                    border: '1px solid #ccc',
                                    borderRadius: '5px',
                                    padding: '10px',
                                    minHeight: '400px',
                                    overflow: 'auto'
                                }}>
                                    {(() => {
                                        try {
                                            // Parse JSON
                                            const parsedData = typeof errorData === 'string' ? JSON.parse(errorData) : errorData;
                                            // Giải mã Base64
                                            const decodedData = decodeBase64Recursive(parsedData);

                                            return (
                                                <ReactJson
                                                    src={decodedData}
                                                    theme="rjv-default"
                                                    collapsed={1} // Collapse tất cả cấp độ
                                                    enableClipboard={false}
                                                    displayDataTypes={false}
                                                    style={{
                                                        borderRadius: '5px',
                                                        fontSize: '14px'
                                                    }}
                                                />
                                            );
                                        } catch (e) {
                                            console.error('Lỗi parse JSON:', e);
                                            return (
                                                <pre style={{
                                                    whiteSpace: 'pre-wrap',
                                                    wordWrap: 'break-word',
                                                    fontSize: '14px',
                                                    fontFamily: 'monospace',
                                                    color: '#d9534f'
                                                }}>
                                                    {errorData || 'Không có dữ liệu'}
                                                </pre>
                                            );
                                        }
                                    })()}
                                </div>
                            </FormGroup>


                            <>
                                {
                                    attachment ?
                                        <FormGroup style={{ marginTop: '15px' }}>
                                            <Label>Attachment</Label>
                                            <div style={{ display: 'flex', alignItems: 'center', marginTop: '-15px' }}>
                                                <Input value={attachment || 'Không có tệp đính kèm'} readOnly />
                                                <Button color="success" style={{ marginLeft: '10px', width: '150px' }} onClick={downloadAttachment}>
                                                    Tải tệp
                                                </Button>
                                            </div>
                                        </FormGroup>
                                        :
                                        null
                                }
                            </>

                            <FormGroup style={{ marginTop: '15px' }}>
                                <Label>Images</Label>
                                <div style={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    gap: '10px'
                                }}>
                                    {imageURLs.length > 0 ? (
                                        imageURLs.map((url, index) => (
                                            <div
                                                key={index}
                                                style={{
                                                    width: '100px',
                                                    height: '100px',
                                                    overflow: 'hidden',
                                                    borderRadius: '8px',
                                                    cursor: 'pointer',
                                                    border: '1px solid #ccc',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    background: '#f9f9f9'
                                                }}
                                                onClick={() => {
                                                    Swal.fire({
                                                        imageUrl: url,
                                                        imageAlt: `Attachment ${index + 1}`,
                                                        showCloseButton: true,
                                                        showConfirmButton: false,
                                                        width: '80%',
                                                        background: '#fff'
                                                    });
                                                }}
                                            >
                                                <img
                                                    src={url}
                                                    alt={`Attachment ${index + 1}`}
                                                    style={{
                                                        width: '100%',
                                                        height: '100%',
                                                        objectFit: 'cover'
                                                    }}
                                                />
                                            </div>
                                        ))
                                    ) : (
                                        <p style={{ fontStyle: 'italic', color: '#888' }}>Không có ảnh đính kèm</p>
                                    )}
                                </div>
                            </FormGroup>
                        </CardBody>
                    </Card>
                </Col>

                {/* Thao tác (3 cột) */}
                <Col lg="3" md="4">
                    {/* Bảng Nhận Việc */}
                    <Card style={{ backgroundColor: '#fff', borderRadius: '10px', padding: '15px', margin: '20px auto' }}>
                        <CardHeader>
                            <Label style={{ fontSize: '24px', color: 'DimGray', fontWeight: 'bold' }}>Xử lý</Label>
                        </CardHeader>
                        <CardBody >
                            <FormGroup>
                                <Label>Người xử lý</Label>
                                <Input
                                    type="select"
                                    value={selectedMember?.username || ''} // Hiển thị username nếu đã chọn
                                    onChange={(e) => {
                                        const selected = members.find(member => member.username === e.target.value);
                                        setSelectedMember(selected || null);
                                    }}
                                >
                                    {members.map(member => (
                                        <option key={member.id} value={member.username}>
                                            {member.fullname} ({member.username})
                                        </option>
                                    ))}
                                </Input>
                            </FormGroup>

                            <FormGroup style={{ marginTop: '15px' }}>
                                <Label>Trạng thái</Label>
                                <Input type="select" value={status} onChange={(e) => setStatus(e.target.value)}>
                                    <option value="Pending">Pending</option>
                                    <option value="InProgress">InProgress</option>
                                    <option value="Resolved">Resolved</option>
                                    <option value="Ignored">Ignored</option>
                                </Input>
                            </FormGroup>
                            <FormGroup style={{ marginTop: '15px' }}>
                                <Label>Ghi chú từ khách hàng</Label>
                                <Input type="textarea" value={note} style={{ minHeight: '150px' }} disabled />
                            </FormGroup>

                            <FormGroup style={{ marginTop: '15px' }}>
                                <Label>Phản hồi cho khách hàng</Label>
                                <Input type="textarea" value={adminResponse} style={{ minHeight: '200px' }} onChange={(e) => setAdminResponse(e.target.value)} />
                            </FormGroup>
                        </CardBody>
                        <CardFooter style={{ textAlign: 'left', marginTop: '-10px', marginBottom: '10px' }}>
                            <Button color="success" style={{ width: '100%' }} onClick={updateReport}>Cập nhập</Button>
                            <Button color="danger" style={{ width: '100%', marginTop: '10px' }} onClick={deleteReport}>Xóa báo lỗi</Button>
                        </CardFooter>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}



export default ReportDetails;
