import axios from 'axios';
import Deco from "security/deco";
import authHeader from './auth-header';

const API_URL_V3 = "https://report.dhbtools.com/admin";

class ReportService {

    getAllUsers() {
        return axios.get(`${API_URL_V3}/users`, { headers: authHeader() })
            .then(response => response.data)
            .catch(error => {
                console.error("Lỗi khi lấy danh sách người dùng", error);
                throw error;
            });
    }

    getAllReportsByUser() {
        return axios.get(`${API_URL_V3}/reports-by-user`, { headers: authHeader() })
            .then(response => response.data)
            .catch(error => {
                console.error("Lỗi khi lấy danh sách báo cáo:", error);
                throw error;
            });
    }

    /**
    * 📑 Lấy danh sách tất cả báo cáo lỗi theo người dùng
    * GET /admin/reports/handler
    */
    getAllReportsByAdmin() {
        return axios.get(`${API_URL_V3}/reports-by-admin`, { headers: authHeader() })
            .then(response => response.data)
            .catch(error => {
                console.error("Lỗi khi lấy danh sách báo cáo:", error);
                throw error;
            });
    }


    /**
     * 📊 Lấy chi tiết báo cáo
     * GET /admin/reports/:id
     */
    getReportDetails(id) {
        return axios.get(`${API_URL_V3}/reports/${id}`, { headers: authHeader() })
            .then(response => response.data)
            .catch(error => {
                console.error("Lỗi khi lấy chi tiết báo cáo:", error);
                throw error;
            });
    }

    /**
     * 🛠️ Cập nhật báo cáo lỗi (handledBy, status, adminNote)
     * PUT /admin/reports/:id/update
     */
    updateUserReport(id, formData) {
        return axios.put(`${API_URL_V3}/reports/${id}`, formData, {
            headers: authHeader()
        })
            .then(response => response.data)
            .catch(error => {
                console.error("Lỗi khi cập nhật báo cáo:", error);
                throw error;
            });
    }

    updateAdminReport(id, formData) {
        return axios.put(`${API_URL_V3}/reports/${id}`, formData, {
            headers: authHeader()
        })
            .then(response => response.data)
            .catch(error => {
                console.error("Lỗi khi cập nhật báo cáo:", error);
                throw error;
            });
    }


    /**
     * 📝 Admin trả lời báo cáo lỗi và cập nhật trạng thái
     * PUT /admin/reports/:id/reply
     */
    replyToReport(id, adminResponse, status) {
        const data = { adminResponse, status };

        return axios.put(`${API_URL_V3}/${id}/reply`, data, {
            headers: authHeader()
        })
            .then(response => response.data)
            .catch(error => {
                console.error("Lỗi khi trả lời báo cáo:", error);
                throw error;
            });
    }

    deleteReport(id) {
        return axios.delete(`${API_URL_V3}/reports/${id}`, { headers: authHeader() })
            .then(response => response.data)
            .catch(error => {
                console.error("Lỗi khi xóa báo cáo:", error);
                throw error;
            });
    }



    /**
     * 📝 Người dùng tạo báo cáo lỗi với hình ảnh và tệp ZIP
     * POST /report
     */
    createReport(formData) {
        return axios.post(`${API_URL_V3}/reports`, formData, {
            headers: {
                ...authHeader(),
                "Content-Type": "multipart/form-data"
            }
        })
            .then(response => response.data)
            .catch(error => {
                console.error("Lỗi khi tạo báo cáo lỗi:", error);
                throw error;
            });
    }

    /**
    * 🖼️ Tải ảnh đính kèm từ báo cáo lỗi
    * GET /admin/reports/image/:filename
    */
    downloadImage(filename) {
        return axios.get(`${API_URL_V3}/reports/image/${filename}`, {
            headers: authHeader(),
            responseType: 'blob' // Tải ảnh dưới dạng nhị phân (blob)
        })
            .then(response => {
                // Tạo URL từ Blob
                const url = window.URL.createObjectURL(new Blob([response.data]));
                return url; // Trả về URL để hiển thị trên Frontend
            })
            .catch(error => {
                console.error("Lỗi khi tải ảnh đính kèm:", error);
                throw error;
            });
    }

    /**
     * 📥 Admin tải tệp đính kèm từ báo cáo lỗi
     * GET /admin/reports/attachment/:filename
     */
    downloadAttachment(urlAttachment, stackTrace) {
        const filename = urlAttachment.split('/').pop();
        const ext = filename.split('.').pop();

        return axios.get(urlAttachment, {
            headers: authHeader(),
            responseType: 'blob' // Đảm bảo tải tệp dưới dạng nhị phân
        }).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${stackTrace}.${ext}`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }).catch(error => {
            console.error("Lỗi khi tải tệp đính kèm:", error);
            throw error;
        });
    }
}

export default new ReportService();
