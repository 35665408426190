import axios from 'axios';
import Deco from "security/deco";
import authHeader from './auth-header';

const API_URL_V3 = "https://download.dhbtools.com";

class ReportService {

    getAllTool() {
        return axios.get(`${API_URL_V3}/tools`, { headers: authHeader() })
            .then(response => response.data)
            .catch(error => {
                console.error("Lỗi khi lấy danh sách phần mềm", error);
                throw error;
            });
    }

    // updateToolFile(id, formData) {
    //     return axios.put(`${API_URL_V3}/tools/${id}`, formData, {
    //         headers: {
    //             ...authHeader(),
    //             "Content-Type": "multipart/form-data"
    //         }
    //     })
    //         .then(response => response.data)
    //         .catch(error => {
    //             console.error("Lỗi khi tạo báo cáo lỗi:", error);
    //             throw error;
    //         });
    // }

    // updateToolFile(toolId, file, onUploadProgress) {
    //     const chunkSize = 50 * 1024 * 1024; // 50MB
    //     const totalChunks = Math.ceil(file.size / chunkSize);

    //     const uploadChunk = async (chunk, chunkIndex) => {
    //         const formData = new FormData();
    //         formData.append("file", chunk);

    //         return axios.put(`${API_URL_V3}/tools/${toolId}`, formData, {
    //             headers: {
    //                 ...authHeader(),
    //                 "Content-Type": "multipart/form-data",
    //                 "x-file-name": file.name,
    //                 "x-chunk-index": chunkIndex,
    //                 "x-total-chunks": totalChunks,
    //             },
    //             onUploadProgress,
    //         });
    //     };

    //     const uploadFileChunks = async () => {
    //         for (let i = 0; i < totalChunks; i++) {
    //             const chunk = file.slice(i * chunkSize, (i + 1) * chunkSize);
    //             await uploadChunk(chunk, i);
    //         }
    //     };

    //     return uploadFileChunks();
    // }

    async uploadFileChunked(toolId, file, onUploadProgress) {
        const chunkSize = 50 * 1024 * 1024; // 50MB mỗi chunk
        const totalChunks = Math.ceil(file.size / chunkSize);
        let uploadedBytes = 0; // Biến theo dõi tổng byte đã tải lên

        for (let i = 0; i < totalChunks; i++) {
            const chunk = file.slice(i * chunkSize, (i + 1) * chunkSize);

            const headers = {
                ...authHeader(),
                'Content-Type': 'application/octet-stream', // Chỉ định kiểu dữ liệu
                'x-file-name': file.name,
                'x-chunk-index': i,
                'x-total-chunks': totalChunks,
            };

            try {
                // Gửi từng chunk lên server
                await axios.put(`${API_URL_V3}/tools/${toolId}`, chunk, {
                    headers,
                    onUploadProgress: (progressEvent) => {
                        // Tiến trình của chunk hiện tại
                        const chunkProgress = progressEvent.loaded;

                        // Tính tổng byte đã tải lên
                        const totalUploaded = uploadedBytes + chunkProgress;

                        // Tính tiến độ tổng thể (phần trăm)
                        const totalProgress = Math.min(
                            Math.round((totalUploaded / file.size) * 100),
                            100
                        );

                        // Gọi callback với tiến trình tổng thể
                        if (onUploadProgress) {
                            onUploadProgress({ loaded: totalUploaded, total: file.size, progress: totalProgress });
                        }
                    },
                });

                // Cập nhật tổng số byte đã tải lên sau khi hoàn thành chunk
                uploadedBytes += chunk.size;
            } catch (error) {
                console.error(`Error uploading chunk ${i + 1} of ${totalChunks}`, error);
                throw error; // Dừng upload nếu có lỗi
            }
        }

        console.log('File upload completed successfully.');
    }


    updateRequireAuthen(id, requireAuthen) {
        return axios.put(`${API_URL_V3}/tools/${id}/auth`, requireAuthen, { headers: authHeader() })
            .then(response => response.data)
            .catch(error => {
                console.error("Lỗi khi cập nhật yêu cầu xác thực:", error);
                throw error;
            });
    }

    getToken(tool) {
        return axios.get(`${API_URL_V3}/download/${tool}/token`, { headers: authHeader() })
            .then(response => response.data)
            .catch(error => {
                console.error("Lỗi khi lấy mã token:", error);
                throw error;
            });
    }
}

export default new ReportService();
