// 📄 ReportDetails.js
import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import {
    FormGroup,
    Label,
    Input,
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Row,
    Col
} from 'reactstrap';

import reportService from 'services/report.service';
import authService from 'services/auth.service';


const jwt = require('jsonwebtoken');

function ReportDetails(props) {
    const data = props.data || {};
    const onBack = props.onBack || (() => { });

    const [id, setId] = useState(data.id || '');
    const [reportBy, setReportBy] = useState(data.reportBy || '');
    const [members, setMembers] = useState([]);
    const [selectedMember, setSelectedMember] = useState(data.handledBy || '');
    const [toolName, setToolName] = useState(data.toolName || '');
    const [stackTrace, setStackTrace] = useState(data.stackTrace || '');
    const [imageURLs, setImageURLs] = useState([]);

    const [status, setStatus] = useState(data.status || 'Pending');
    const [adminResponse, setAdminResponse] = useState(data.adminResponse || '');
    const [attachment, setAttachment] = useState(data.attachment || '');
    const [errorData, setErrorData] = useState(data.errorData || '');

    const [note, setNote] = useState('');


    const [currentUsername, setCurrentUsername] = useState('');

    useEffect(() => {
        fetchMembers();
        fetchReportDetails();

        // Lấy thông tin người dùng hiện tại
        const user = authService.getCurrentUserData();
        const decoded = jwt.decode(user.token, { complete: true });
        setCurrentUsername(decoded.payload.username);
    }, [id]);

    /** 📥 Lấy danh sách thành viên */
    function fetchMembers() {
        reportService.getAllUsers()
            .then(response => {
                var selected = response.find(member => member.username === selectedMember.username);
                setMembers(response);
                setSelectedMember(selected);
            })
            .catch(error => {
                console.error("Lỗi khi lấy danh sách thành viên:", error);
            });
    }

    /** 📥 Tải chi tiết báo cáo */
    async function fetchReportDetails() {
        const result = await reportService.getReportDetails(id);
        const report = result.data;

        setAdminResponse(report.adminResponse);
        setAttachment(report.attachment);
        setErrorData(report.errorData);
        setNote(report.note);

        const urls = await Promise.all(
            report.imageAttachments.map(async (filename) => {
                const url = await reportService.downloadImage(filename.split('/').pop());
                return url;
            })
        );

        setImageURLs(urls);
    }

    /** 📥 Tải tệp đính kèm */
    function downloadAttachment() {
        if (!attachment) {
            Swal.fire('Thông báo', 'Không có tệp đính kèm.', 'info');
            return;
        }
        reportService.downloadAttachment(attachment, stackTrace)
            .catch(() => {
                Swal.fire('Lỗi!', 'Không thể tải tệp đính kèm.', 'error');
            });
    }

    /** 📤 Gửi phản hồi */
    function updateReport() {
        const data = {
            handlerByUsername: selectedMember.username,
            stackTrace: stackTrace,
            errorData: errorData,
            status: status,
            note: note,
            adminResponse: adminResponse

        };

        reportService.updateAdminReport(id, data)
            .then(() => {
                Swal.fire('Thành công!', 'Cập nhập báo cáo thành công.', 'success');
                onBack();
            })
            .catch(() => {
                Swal.fire('Lỗi!', 'Không thể cập nhập báo cáo.', 'error');
            });
    }

    return (
        <div className="content">
            <Row>
                {/* Chi tiết báo cáo (9 cột) */}
                <Col lg="9" md="8">
                    <Card style={{ backgroundColor: '#fff', borderRadius: '10px', padding: '15px', margin: '20px auto' }}>
                        <CardHeader>
                            <Label style={{ fontSize: '24px', color: 'DimGray', fontWeight: 'bold' }}>Chi tiết báo lỗi</Label>
                        </CardHeader>
                        <CardBody >
                            <Row>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label>Người báo lỗi</Label>
                                        <Input value={reportBy.fullname} disabled />
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label>Tool Name</Label>
                                        <Input value={toolName} disabled />
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label>Stack Trace</Label>
                                        <Input value={stackTrace} onChange={(e) => setStackTrace(e.target.value)} />
                                    </FormGroup>
                                </Col>
                            </Row>

                            <FormGroup style={{ marginTop: '5px' }}>
                                <Label>Data</Label>
                                <Input type="textarea" value={errorData} onChange={(e) => {
                                    if (reportBy.username === currentUsername) {
                                        setErrorData(e.target.value)
                                    }
                                }} style={{ minHeight: '332px' }} />
                            </FormGroup>

                            <>
                                {
                                    attachment ?
                                        <FormGroup style={{ marginTop: '15px' }}>
                                            <Label>Attachment</Label>
                                            <div style={{ display: 'flex', alignItems: 'center', marginTop: '-15px' }}>
                                                <Input value={attachment} readOnly />
                                                <Button color="success" style={{ marginLeft: '10px', width: '150px' }} onClick={downloadAttachment}>
                                                    Tải tệp
                                                </Button>
                                            </div>
                                        </FormGroup>
                                        :
                                        null
                                }
                            </>

                            <FormGroup style={{ marginTop: '15px' }}>
                                <Label>Images</Label>
                                <div style={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    gap: '10px',
                                    marginTop: '10px'
                                }}>
                                    {imageURLs.length > 0 ? (
                                        imageURLs.map((url, index) => (
                                            <div
                                                key={index}
                                                style={{
                                                    width: '100px',
                                                    height: '100px',
                                                    overflow: 'hidden',
                                                    borderRadius: '8px',
                                                    cursor: 'pointer',
                                                    border: '1px solid #ccc',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    background: '#f9f9f9'
                                                }}
                                                onClick={() => {
                                                    Swal.fire({
                                                        imageUrl: url,
                                                        imageAlt: `Attachment ${index + 1}`,
                                                        showCloseButton: true,
                                                        showConfirmButton: false,
                                                        width: '80%',
                                                        background: '#fff'
                                                    });
                                                }}
                                            >
                                                <img
                                                    src={url}
                                                    alt={`Attachment ${index + 1}`}
                                                    style={{
                                                        width: '100%',
                                                        height: '100%',
                                                        objectFit: 'cover'
                                                    }}
                                                />
                                            </div>
                                        ))
                                    ) : (
                                        <p style={{ fontStyle: 'italic', color: '#888' }}>Không có ảnh đính kèm</p>
                                    )}
                                </div>
                            </FormGroup>
                        </CardBody>
                    </Card>
                </Col>

                {/* Thao tác (3 cột) */}
                <Col lg="3" md="4">
                    {/* Bảng Nhận Việc */}
                    <Card style={{ backgroundColor: '#fff', borderRadius: '10px', padding: '15px', margin: '20px auto' }}>
                        <CardHeader >
                            <Label style={{ fontSize: '24px', color: 'DimGray', fontWeight: 'bold' }}>Xử lý</Label>
                        </CardHeader>
                        <CardBody>
                            <FormGroup>
                                <Label>Người xử lý</Label>
                                <Input
                                    type="select"
                                    value={selectedMember?.username || ''} // Hiển thị username nếu đã chọn
                                    onChange={(e) => {
                                        if (reportBy.username === currentUsername) {
                                            const selected = members.find(member => member.username === e.target.value);
                                            setSelectedMember(selected || null);
                                        }
                                    }}
                                >
                                    {members.map(member => (
                                        <option key={member.id} value={member.username}>
                                            {member.fullname} ({member.username})
                                        </option>
                                    ))}
                                </Input>
                            </FormGroup>
                            <FormGroup style={{ marginTop: '15px' }}>
                                <Label>Trạng thái</Label>
                                <Input type="select" value={status} onChange={(e) => setStatus(e.target.value)}>
                                    <option value="Pending">Pending</option>
                                    <option value="InProgress">InProgress</option>
                                    <option value="Resolved">Resolved</option>
                                    <option value="Ignored">Ignored</option>
                                </Input>
                            </FormGroup>
                            <FormGroup style={{ marginTop: '15px' }}>
                                <Label>Ghi chú từ người giao việc</Label>
                                <Input type="textarea" value={note} onChange={(e) => {
                                    if (reportBy.username === currentUsername) {
                                        setNote(e.target.value)
                                    }
                                }} style={{ minHeight: '150px' }} />
                            </FormGroup>

                            <FormGroup style={{ marginTop: '15px' }}>
                                <Label>Phản hồi cho người giao việc</Label>
                                <Input type="textarea" value={adminResponse} style={{ minHeight: '150px' }} onChange={(e) => setAdminResponse(e.target.value)} />
                            </FormGroup>
                        </CardBody>
                        <CardFooter style={{ textAlign: 'left', marginTop: '-10px', marginBottom: '10px' }}>
                            <Button color="success" style={{ width: '100%' }} onClick={updateReport}>Cập nhập</Button>
                        </CardFooter>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default ReportDetails;
