// 📄 Report.js
import React, { useState, useEffect } from 'react';
import { Table, Input, Button, Col, Card, CardHeader, CardBody, CardFooter, Label } from 'reactstrap';
import Swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';
import ReportDetails from '../components/Childrens/UserReportDetails';
import reportService from 'services/report.service';

function Report() {
    const [reports, setReports] = useState([]);
    const [search, setSearch] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState('list'); // 'list' hoặc 'detail'
    const [dataProps, setDataProps] = useState(null);

    useEffect(() => {
        loadReports(currentPage);
    }, [currentPage]);

    /**
     * 📥 Load báo cáo theo phân trang
     */
    function loadReports(page) {
        setLoading(true);
        reportService.getAllReportsByUser(page, 10).then((result) => {
            setReports(result.data);
            setTotalPages(result.totalPages);
        }).catch((error) => {
            Swal.fire('Lỗi!', 'Không thể tải báo cáo', 'error');
        }).finally(() => {
            setLoading(false);
        });
    }


    /**
     * 🔍 Tìm kiếm báo cáo
     */
    function searchSpace(event) {
        setSearch(event.target.value);
    }

    /**
     * 🛠️ Rút gọn chuỗi
     */
    function truncateText(text, maxLength) {
        if (!text) return '';
        return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
    }

    /**
     * 🖱️ Xem chi tiết báo cáo
     */
    function toDetailView(report) {
        setPage('detail');
        setDataProps(report);
    }

    /**
     * ⬅️ Quay lại danh sách báo cáo
     */
    function back() {
        setPage('list');
        loadReports(currentPage);
    }

    /**
     * 📊 Hiển thị bảng báo cáo
     */
    function renderTable() {
        return (
            <Table className="table-hover" style={{ marginTop: "10px", lineHeight: "15px" }}>
                <thead className="text-primary">
                    <tr>
                        <th>Username</th>
                        <th>Fullname</th>
                        <th>Người xử lý</th>
                        <th>Phần mềm</th>
                        <th>Vị trí lỗi</th>
                        <th>Dữ liệu lỗi</th>
                        <th>Ghi chú</th>
                        <th>Trạng Thái</th>
                        <th>Xóa</th>
                    </tr>
                </thead>
                <tbody>
                    {reports
                        .filter((report) => {
                            return search === '' ||
                                report.username.toLowerCase().includes(search.toLowerCase()) ||
                                report.toolName.toLowerCase().includes(search.toLowerCase()) ||
                                report.errorData.toLowerCase().includes(search.toLowerCase()) ||
                                report.status.toLowerCase().includes(search.toLowerCase());
                        })
                        .map((report, index) => (
                            <tr
                                key={index}
                                onClick={() => toDetailView(report)}
                                style={{
                                    cursor: 'pointer',
                                    backgroundColor: report.status === 'Pending' ? '#f8d7da' : // Đỏ nhạt
                                        report.status === 'InProgress' ? '#fff3cd' : // Vàng nhạt
                                            report.status === 'Resolved' ? '#d4edda' : // Xanh lá nhạt
                                                report.status === 'Ignored' ? '#cce5ff' : 'transparent', // Xanh da trời nhạt
                                    transition: 'background-color 0.3s ease'
                                }}
                            >
                                <td><b>{report.reportBy.username}</b></td>
                                <td>{report.reportBy.fullname}</td>
                                <td>{report.handledBy ? report.handledBy.fullname : "-"}</td>
                                <td><b>{report.toolName}</b></td>
                                <td>{truncateText(report.stackTrace, 30)}</td>
                                <td>{truncateText(report.errorDataShort, 30)}</td>
                                <td>{truncateText(report.note, 30)}</td>
                                <td><b>{report.status}</b></td>
                                <td>
                                    <Button
                                        color="danger"
                                        size="sm"
                                        onClick={(e) => {
                                            // Chặn sự kiện nổi lên thẻ <tr>
                                            e.stopPropagation();

                                            Swal.fire({
                                                title: 'Xác nhận xóa báo cáo?',
                                                icon: 'warning',
                                                showCancelButton: true,
                                                confirmButtonText: 'Xóa',
                                                cancelButtonText: 'Hủy',
                                            }).then((result) => {
                                                if (result.isConfirmed) {
                                                    reportService.deleteReport(report.id).then(() => {
                                                        Swal.fire('Thành công!', 'Xóa báo cáo thành công', 'success');
                                                        loadReports(currentPage);
                                                    }).catch((error) => {
                                                        Swal.fire('Lỗi!', `Không thể xóa báo cáo <br><br> ${error}`, 'error');
                                                    });
                                                }
                                            });
                                        }}
                                    >
                                        Xóa
                                    </Button>
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </Table>
        );
    }

    return (
        <div className="content" >
            {page === 'detail' ? (
                <>
                    <Button color="info" onClick={back} >
                        &laquo; Quay lại
                    </Button>
                    <ReportDetails data={dataProps} onBack={back} />
                </>
            ) : (
                <Card style={{ backgroundColor: '#fff', borderRadius: '10px', padding: '20px', margin: '20px auto' }}>
                    <CardHeader className="d-flex justify-content-between align-items-center">
                        <Label style={{ fontSize: '24px', color: 'DimGray', fontWeight: 'bold' }}>Danh sách báo lỗi từ người dùng</Label>

                        <Col md="4">
                            <Input
                                style={{ height: "38px" }}
                                value={search}
                                onChange={searchSpace}
                                placeholder="Tìm kiếm báo cáo..."
                            />
                        </Col>
                    </CardHeader>
                    <CardBody>
                        {loading ? <p>Đang tải...</p> : renderTable()}
                    </CardBody>
                    <CardFooter className="d-flex justify-content-between">
                        <Button
                            disabled={currentPage === 1}
                            onClick={() => setCurrentPage(currentPage - 1)}
                        >
                            Trang trước
                        </Button>
                        <span>Trang {currentPage} / {totalPages}</span>
                        <Button
                            disabled={currentPage === totalPages}
                            onClick={() => setCurrentPage(currentPage + 1)}
                        >
                            Trang sau
                        </Button>
                    </CardFooter>
                </Card>
            )}
        </div>
    );
}

export default Report;
