import React, { useState, useEffect } from 'react';
import {
    Table,
    Button,
    Card,
    CardHeader,
    CardBody,
    Breadcrumb,
    BreadcrumbItem,
    Input,
    Progress,
    Dropdown,
    DropdownMenu,
    DropdownItem,
    Toast,
    ToastBody,
    UncontrolledDropdown,
    DropdownToggle,
    Spinner
} from 'reactstrap';
import Toastify from 'toastify-js';
import 'toastify-js/src/toastify.css';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import Deco from "security/deco"

import DownloadFileService from 'services/downloadFile.service';
import authHeader from 'services/auth-header';

function DownloadFile() {
    const [files, setFiles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [breadcrumb, setBreadcrumb] = useState(["root"]);
    const [uploadProgress, setUploadProgress] = useState([]);
    const [isDragging, setIsDragging] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [dragCounter, setDragCounter] = useState(0); // Thêm counter để theo dõi sự kiện drag
    // Thêm state cho danh sách tool
    const [tools, setTools] = useState([]);
    const [contextMenu, setContextMenu] = useState({
        visible: false,
        x: 0,
        y: 0,
        onTable: false // Thêm state để biết vị trí click có phải trên bảng không
    });

    const baseURL = "https://download.dhbtools.com/files";

    const formatSize = (size) => {
        if (size < 1024) return `${size} B`;
        if (size < 1024 * 1024) return `${(size / 1024).toFixed(2)} KB`;
        if (size < 1024 * 1024 * 1024) return `${(size / (1024 * 1024)).toFixed(2)} MB`;
        return `${(size / (1024 * 1024 * 1024)).toFixed(2)} GB`;
    };

    const formatDate = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        const dd = String(date.getDate()).padStart(2, '0');
        const mm = String(date.getMonth() + 1).padStart(2, '0');
        const yyyy = date.getFullYear();
        const HH = String(date.getHours()).padStart(2, '0');
        const MM = String(date.getMinutes()).padStart(2, '0');
        const SS = String(date.getSeconds()).padStart(2, '0');
        return `${dd}/${mm}/${yyyy} ${HH}:${MM}:${SS}`;
    };

    useEffect(() => {
        loadFiles("root");
    }, []);

    // Thêm event listener cho phím Delete
    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === 'Delete' && selectedFiles.length > 0) {
                // Ngăn chặn hành động mặc định của phím Delete
                e.preventDefault();
                // Gọi hàm xóa
                handleDeleteFiles();
            }
        };

        // Thêm event listener
        document.addEventListener('keydown', handleKeyDown);

        // Cleanup khi component unmount
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [selectedFiles]); // Thêm selectedFiles vào dependencies


    // Thêm useEffect để load danh sách tool
    useEffect(() => {
        DownloadFileService.getToolList()
            .then((result) => {
                setTools(result.data);
            })
            .catch((error) => {
                Toastify({
                    text: `Không thể tải danh sách tool: ${error}`,
                    backgroundColor: "#f44336",
                    close: true,
                    position: "bottom",
                    gravity: "right"
                }).showToast();
            });
    }, []);


    function loadFiles(root) {
        setLoading(true);
        DownloadFileService.getFiles(root === "root" ? "" : root)
            .then((result) => {
                const sortedFiles = result.data.sort((a, b) => {
                    if (a.type === b.type) return a.name.localeCompare(b.name);
                    return a.type === 'folder' ? -1 : 1;
                });
                setFiles(sortedFiles);
            })
            .catch((error) => {
                Toastify({
                    text: `Không thể tải dữ liệu: ${error}`,
                    backgroundColor: "#f44336",
                    close: true,
                    position: "right",
                    gravity: "bottom"
                }).showToast();
            })
            .finally(() => {
                setLoading(false);
            });
    }

    function handleFolderClick(folderName) {
        const newPath = [...breadcrumb, folderName];
        setBreadcrumb(newPath);
        loadFiles(newPath.slice(1).join("/"));
    }

    function handleBreadcrumbClick(index) {
        const newPath = breadcrumb.slice(0, index + 1);
        setBreadcrumb(newPath);
        loadFiles(newPath.slice(1).join("/"));
    }

    function handleGoBack() {
        if (breadcrumb.length > 1) {
            const newPath = breadcrumb.slice(0, -1);
            setBreadcrumb(newPath);
            loadFiles(newPath.slice(1).join("/"));
        }
    }


    function handleDeleteFiles() {
        const currentPath = breadcrumb.slice(1).join("/") || "root";
        if (selectedFiles.length === 0) {
            Toastify({
                text: "Vui lòng chọn tệp/thư mục để xóa.",
                backgroundColor: "#ff9800",
                close: true,
                position: "right",
                gravity: "bottom"
            }).showToast();
            return;
        }

        Swal.fire({
            title: 'Xác nhận xóa',
            html: selectedFiles.length > 1
                ? `Bạn có chắc chắn muốn xóa <b>${selectedFiles.length}</b> mục đã chọn?`
                : `Bạn có chắc chắn muốn xóa <b>${selectedFiles[0]}</b>?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Xóa',
            cancelButtonText: 'Hủy'
        }).then((result) => {
            if (result.isConfirmed) {
                DownloadFileService.deleteFiles(currentPath, selectedFiles)
                    .then(() => {
                        Swal.fire(
                            'Đã xóa!',
                            selectedFiles.length > 1
                                ? 'Các mục đã chọn đã được xóa.'
                                : 'Mục đã chọn đã được xóa.',
                            'success'
                        );
                        loadFiles(currentPath);
                        setSelectedFiles([]);
                    })
                    .catch((error) => {
                        Swal.fire(
                            'Lỗi!',
                            `Không thể xóa: ${error}`,
                            'error'
                        );
                    });
            }
        });
    }

    function handleCreateFolder() {
        Swal.fire({
            title: 'Tạo thư mục mới',
            input: 'text',
            inputPlaceholder: 'Tên thư mục',
            showCancelButton: true,
            confirmButtonText: 'Tạo',
            cancelButtonText: 'Hủy',
            inputAttributes: {
                autocomplete: 'off' // Thêm thuộc tính này để tắt gợi ý
            },
        }).then((result) => {
            if (result.isConfirmed && result.value) {
                const currentPath = breadcrumb.slice(1).join("/") || "root";
                const payload = { folderName: result.value };
                DownloadFileService.createFolder(currentPath, payload)
                    .then(() => {
                        Toastify({
                            text: "Thư mục đã được tạo.",
                            backgroundColor: "#4caf50",
                            close: true,
                            position: "right",
                            gravity: "bottom"
                        }).showToast();
                        loadFiles(currentPath);
                    })
                    .catch((error) => {
                        Toastify({
                            text: `Không thể tạo thư mục: ${error}`,
                            backgroundColor: "#f44336",
                            close: true,
                            position: "right",
                            gravity: "bottom"
                        }).showToast();
                    });
            }
        });
    }

    // const handleUpload = (event) => {
    //     const filesToUpload = event.target.files;
    //     if (filesToUpload.length > 0) {
    //         Array.from(filesToUpload).forEach((file) => {
    //             const formData = new FormData();
    //             formData.append('file', file);

    //             // Lấy đường dẫn hiện tại
    //             const currentPath = breadcrumb.length > 1 ? breadcrumb.slice(1).join("/") : "root";

    //             DownloadFileService.uploadFile(currentPath, formData, (progressEvent) => {
    //                 const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
    //                 setUploadProgress((prev) => {
    //                     const existingFile = prev.find((f) => f.name === file.name);
    //                     if (existingFile) {
    //                         return prev.map((f) => f.name === file.name ? { ...f, progress } : f);
    //                     }
    //                     return [...prev, { name: file.name, progress }];
    //                 });
    //             })
    //                 .then(() => {
    //                     setUploadProgress((prev) => {
    //                         return prev.map((item) =>
    //                             item.name === file.name ? { ...item, progress: 100, completed: true } : item
    //                         );
    //                     });

    //                     setTimeout(() => {
    //                         setUploadProgress((prev) => prev.filter((item) => item.name !== file.name));
    //                         loadFiles(currentPath);
    //                     }, 1000);

    //                     Toastify({
    //                         text: `Tệp ${file.name} đã được tải lên thành công.`,
    //                         backgroundColor: "#4caf50",
    //                         close: true,
    //                         position: "right",
    //                         gravity: "bottom"
    //                     }).showToast();
    //                 })
    //                 .catch(() => {
    //                     Toastify({
    //                         text: `Không thể tải lên tệp: ${file.name}`,
    //                         backgroundColor: "#f44336",
    //                         close: true,
    //                         position: "right",
    //                         gravity: "bottom"
    //                     }).showToast();
    //                 });
    //         });
    //     }
    // };

    const handleUpload = (event) => {
        const filesToUpload = event.target.files;
        if (filesToUpload.length > 0) {
            Array.from(filesToUpload).forEach((file) => {
                // Lấy đường dẫn hiện tại
                const currentPath = breadcrumb.length > 1 ? breadcrumb.slice(1).join("/") : "root";

                DownloadFileService.uploadFileChunked(currentPath, file, (progressEvent) => {
                    const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                    setUploadProgress((prev) => {
                        const existingFile = prev.find((f) => f.name === file.name);
                        if (existingFile) {
                            return prev.map((f) => f.name === file.name ? { ...f, progress } : f);
                        }
                        return [...prev, { name: file.name, progress }];
                    });
                })
                    .then(() => {
                        setUploadProgress((prev) =>
                            prev.map((item) =>
                                item.name === file.name ? { ...item, progress: 100, completed: true } : item
                            )
                        );
                        setTimeout(() => {
                            setUploadProgress((prev) => prev.filter((item) => item.name !== file.name));
                            loadFiles(currentPath);
                        }, 1000);

                        Toastify({
                            text: `Tệp ${file.name} đã được tải lên thành công.`,
                            backgroundColor: "#4caf50",
                            close: true,
                            position: "right",
                            gravity: "bottom"
                        }).showToast();
                    })
                    .catch(() => {
                        Toastify({
                            text: `Không thể tải lên tệp: ${file.name}`,
                            backgroundColor: "#f44336",
                            close: true,
                            position: "right",
                            gravity: "bottom"
                        }).showToast();
                    });
            });
        }
    };


    // Thêm hàm xử lý thay đổi tool
    const handleToolChange = async (fileName, matool) => {
        try {
            await DownloadFileService.updateFileRequireLoginTool(
                breadcrumb.slice(1).join("/") || "root",
                fileName,
                matool
            );
            loadFiles(breadcrumb.slice(1).join("/") || "root");
            Toastify({
                text: "Cập nhật xác thực thành công",
                backgroundColor: "#4caf50",
                close: true,
                position: "right",
                gravity: "bottom"
            }).showToast();
        } catch (error) {
            Toastify({
                text: `Không thể cập nhật xác thực: ${error}`,
                backgroundColor: "#f44336",
                close: true,
                position: "right",
                gravity: "bottom"
            }).showToast();
        }
    };

    // Thêm hàm mới
    const handleCopyUrl = async (filename) => {
        try {
            // Lấy token từ API
            const currentPath = breadcrumb.length > 1 ? breadcrumb.slice(1).join("/") + "/" : "";
            console.log(currentPath);

            const response = await DownloadFileService.generateShareToken(currentPath, filename);
            const token = response.data.token;

            // Tạo URL đầy đủ với token
            const fileUrl = `${baseURL}/download/${currentPath}${filename}?token=${token}`;

            // Hiển thị Swal với TextInput hiển thị URL
            Swal.fire({
                title: 'Sao chép URL',
                html: `
                        <div margin-top: 10px;>
                           <textarea id="file-url" readonly style="width: 100%; height: 150px; padding: 10px; font-size: 14px; resize: none;">${fileUrl}</textarea>
                           <p style="margin-bottom: 10px; margin-top: 10px;">Lưu ý: URL có hiệu lực 1 giờ sau khi tạo</p>
                           <button id="copy-url-btn" style="margin-top: 10px; padding: 5px 15px; background-color: #28a745; color: white; border: none; border-radius: 5px; cursor: pointer;">
                               Sao chép
                           </button>
   
                       </div>
                   `,
                showConfirmButton: false,
                didOpen: () => {
                    // Gắn sự kiện click cho nút "Sao chép" khi Swal đã render
                    const copyButton = Swal.getHtmlContainer()?.querySelector('#copy-url-btn');
                    if (copyButton) {
                        copyButton.addEventListener('click', async () => {
                            try {
                                await navigator.clipboard.writeText(fileUrl);
                                Swal.fire('Thành công!', 'URL đã được sao chép vào clipboard.', 'success');
                            } catch (error) {
                                console.error('Lỗi sao chép URL:', error);
                                Swal.fire('Lỗi!', 'Không thể sao chép URL.', 'error');
                            }
                        });
                    }
                }
            });
        } catch (error) {
            console.error('Lỗi sao chép URL:', error);
            Swal.fire('Lỗi!', 'Không thể lấy URL.', 'error');
        }
    };

    // Thêm hàm xử lý copy URL mới
    const handleCopyUrlAuth = async (filename, requireLoginTool) => {
        try {
            const currentPath = breadcrumb.length > 1 ? breadcrumb.slice(1).join("/") + "/" : "";
            let fileUrl = '';

            if (requireLoginTool === 'public') {
                // URL công khai không cần token
                fileUrl = `${baseURL}/download/${currentPath}${filename}`;
            } else {
                // URL với thông tin xác thực tool
                const user = JSON.parse(Deco(localStorage.getItem('user')));
                fileUrl = `${baseURL}/download/${currentPath}${filename}?tool=${requireLoginTool}&username={username}&password={password}`;
            }

            // Hiển thị Swal với TextInput
            Swal.fire({
                title: 'Sao chép URL',
                html: `
                <div style="margin-top: 10px;">
                    <textarea id="file-url" readonly style="width: 100%; height: 150px; padding: 10px; font-size: 14px; resize: none;">${fileUrl}</textarea>
                    <button id="copy-url-btn" style="margin-top: 10px; padding: 5px 15px; background-color: #28a745; color: white; border: none; border-radius: 5px; cursor: pointer;">
                        Sao chép
                    </button>
                </div>
            `,
                showConfirmButton: false,
                didOpen: () => {
                    const copyButton = Swal.getHtmlContainer()?.querySelector('#copy-url-btn');
                    if (copyButton) {
                        copyButton.addEventListener('click', async () => {
                            try {
                                await navigator.clipboard.writeText(fileUrl);
                                Swal.fire('Thành công!', 'URL đã được sao chép vào clipboard.', 'success');
                            } catch (error) {
                                console.error('Lỗi sao chép URL:', error);
                                Swal.fire('Lỗi!', 'Không thể sao chép URL.', 'error');
                            }
                        });
                    }
                }
            });
        } catch (error) {
            console.error('Lỗi sao chép URL:', error);
            Swal.fire('Lỗi!', 'Không thể lấy URL.', 'error');
        }
    };

    // Xử lý Ctrl + A
    useEffect(() => {
        const handleKeyDown = (e) => {
            if ((e.ctrlKey || e.metaKey) && e.key === 'a') {
                e.preventDefault();
                // Chọn tất cả files
                setSelectedFiles(files.map(file => file.name));
            } else if (e.key === 'Delete' && selectedFiles.length > 0) {
                e.preventDefault();
                handleDeleteFiles();
            }
        };

        document.addEventListener('keydown', handleKeyDown);
        return () => document.removeEventListener('keydown', handleKeyDown);
    }, [files, selectedFiles]);

    // Cải thiện xử lý kéo thả
    const handleDragEnter = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragCounter(prev => prev + 1);
        if (!isDragging) {
            setIsDragging(true);
        }
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragCounter(prev => prev - 1);
        if (dragCounter === 1) {
            setIsDragging(false);
        }
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    // const handleDrop = (e) => {
    //     e.preventDefault();
    //     e.stopPropagation();
    //     setIsDragging(false);
    //     setDragCounter(0);

    //     const files = [...e.dataTransfer.files];
    //     if (files.length > 0) {
    //         files.forEach(file => {
    //             const formData = new FormData();
    //             formData.append('file', file);
    //             const currentPath = breadcrumb.slice(1).join("/") || "root";

    //             DownloadFileService.uploadFile(currentPath, formData, (progressEvent) => {
    //                 const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
    //                 setUploadProgress((prev) => {
    //                     const existingFile = prev.find((f) => f.name === file.name);
    //                     if (existingFile) {
    //                         return prev.map((f) => f.name === file.name ? { ...f, progress } : f);
    //                     }
    //                     return [...prev, { name: file.name, progress }];
    //                 });
    //             })
    //                 .then(() => {
    //                     setUploadProgress((prev) => prev.map((item) =>
    //                         item.name === file.name ? { ...item, progress: 100, completed: true } : item
    //                     ));
    //                     setTimeout(() => {
    //                         setUploadProgress((prev) => prev.filter((item) => item.name !== file.name));
    //                         loadFiles(currentPath);
    //                     }, 1000);
    //                     Toastify({
    //                         text: `Tệp ${file.name} đã được tải lên thành công.`,
    //                         backgroundColor: "#4caf50",
    //                         close: true,
    //                         position: "right",
    //                         gravity: "bottom"
    //                     }).showToast();
    //                 })
    //                 .catch(() => {
    //                     Toastify({
    //                         text: `Không thể tải lên tệp: ${file.name}`,
    //                         backgroundColor: "#f44336",
    //                         close: true,
    //                         position: "right",
    //                         gravity: "bottom"
    //                     }).showToast();
    //                 });
    //         });
    //     }
    // };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);
        setDragCounter(0);

        const files = [...e.dataTransfer.files];
        if (files.length > 0) {
            files.forEach((file) => {
                const currentPath = breadcrumb.slice(1).join("/") || "root";

                DownloadFileService.uploadFileChunked(currentPath, file, (progressEvent) => {
                    const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                    setUploadProgress((prev) => {
                        const existingFile = prev.find((f) => f.name === file.name);
                        if (existingFile) {
                            return prev.map((f) => f.name === file.name ? { ...f, progress } : f);
                        }
                        return [...prev, { name: file.name, progress }];
                    });
                })
                    .then(() => {
                        setUploadProgress((prev) =>
                            prev.map((item) =>
                                item.name === file.name ? { ...item, progress: 100, completed: true } : item
                            )
                        );
                        setTimeout(() => {
                            setUploadProgress((prev) => prev.filter((item) => item.name !== file.name));
                            loadFiles(currentPath);
                        }, 1000);

                        Toastify({
                            text: `Tệp ${file.name} đã được tải lên thành công.`,
                            backgroundColor: "#4caf50",
                            close: true,
                            position: "right",
                            gravity: "bottom"
                        }).showToast();
                    })
                    .catch(() => {
                        Toastify({
                            text: `Không thể tải lên tệp: ${file.name}`,
                            backgroundColor: "#f44336",
                            close: true,
                            position: "right",
                            gravity: "bottom"
                        }).showToast();
                    });
            });
        }
    };


    function toggleFileSelection(fileName, isCtrlPressed, isShiftPressed) {
        setSelectedFiles((prev) => {
            if (isShiftPressed && prev.length > 0) {
                const lastIndex = files.findIndex(f => f.name === prev[prev.length - 1]);
                const currentIndex = files.findIndex(f => f.name === fileName);
                const [start, end] = [Math.min(lastIndex, currentIndex), Math.max(lastIndex, currentIndex)];
                const rangeFiles = files.slice(start, end + 1).map(f => f.name);
                return [...new Set([...prev, ...rangeFiles])];
            }

            if (isCtrlPressed) {
                if (prev.includes(fileName)) {
                    return prev.filter((file) => file !== fileName);
                }
                return [...prev, fileName];
            }

            return [fileName];
        });
    }

    function handleFileClick(event, fileName) {
        const isCtrlPressed = event.ctrlKey || event.metaKey;
        const isShiftPressed = event.shiftKey;
        toggleFileSelection(fileName, isCtrlPressed, isShiftPressed);
    }

    // Xử lý hiển thị menu chuột phải
    function handleContextMenu(e, fileName = null) {
        e.preventDefault();
        const isOnTable = !!fileName; // Kiểm tra xem có click trên file/folder không

        // Nếu click trên file và file chưa được chọn, chọn nó
        if (isOnTable && fileName && !selectedFiles.includes(fileName)) {
            toggleFileSelection(fileName, e.ctrlKey, e.shiftKey);
        }

        setContextMenu({
            visible: true,
            x: e.clientX,
            y: e.clientY,
            onTable: isOnTable
        });
    }

    // Thêm event listener cho toàn bộ CardBody
    function handleCardBodyContextMenu(e) {
        // Chỉ xử lý khi click phải trực tiếp vào CardBody, không phải trên Table
        if (e.target === e.currentTarget) {
            handleContextMenu(e);
        }
    }

    // Đóng menu khi click ra ngoài
    useEffect(() => {
        const handleClick = () => setContextMenu({ ...contextMenu, visible: false });
        document.addEventListener('click', handleClick);
        return () => document.removeEventListener('click', handleClick);
    }, []);

    return (
        <div
            className="content"
        >
            <Card
                style={{
                    backgroundColor: isDragging ? '#f0f8ff' : '#fff',
                    borderRadius: '10px',
                    padding: '15px',
                    margin: '20px auto',
                    transition: 'background-color 0.3s',
                    position: 'relative'
                }}
                onDragEnter={handleDragEnter}
                onDragLeave={handleDragLeave}
                onDragOver={handleDragOver}
                onDrop={handleDrop}
            >

                {/* Thêm overlay khi đang kéo file */}
                {isDragging && (
                    <div
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundColor: 'rgba(0, 0, 0, 0.1)',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRadius: '10px',
                            zIndex: 1000
                        }}
                    >
                        <div
                            style={{
                                padding: '20px',
                                backgroundColor: 'white',
                                borderRadius: '8px',
                                boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
                                display: 'flex',
                                alignItems: 'center',
                                gap: '10px'
                            }}
                        >
                            <i className="fa fa-upload" style={{ fontSize: '24px', color: '#4caf50' }}></i>
                            <span style={{ fontSize: '16px', fontWeight: 'bold' }}>Thả file để tải lên</span>
                        </div>
                    </div>
                )}


                <CardHeader style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '10px' }}>
                    <div className="d-flex align-items-center">
                        <Button
                            color="secondary"
                            onClick={handleGoBack}
                            disabled={breadcrumb.length <= 1}
                            style={{ marginRight: '10px' }}
                        >
                            <i className="fa fa-arrow-left"></i> Quay lại
                        </Button>

                        <nav
                            aria-label="breadcrumb"
                            style={{
                                backgroundColor: '#f0f0f0',
                                padding: '8px 15px',
                                borderRadius: '0.375rem',
                                flex: 1
                            }}
                        >
                            <ol className="breadcrumb m-0" style={{
                                backgroundColor: 'transparent',
                                fontSize: '16px',
                                fontWeight: 'bold',
                                margin: 0,
                                padding: 0
                            }}>
                                {breadcrumb.map((crumb, index) => (
                                    <li
                                        key={index}
                                        className={`breadcrumb-item ${index === breadcrumb.length - 1 ? 'active' : ''}`}
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            padding: '0 15px' // Thêm padding ngang cho mỗi item
                                        }}
                                    >
                                        <i
                                            className="fa fa-folder"
                                            style={{
                                                marginRight: '10px',
                                                color: 'goldenrod'
                                            }}
                                        />
                                        {index === breadcrumb.length - 1 ? (
                                            <span style={{ color: '#6c757d' }}>{crumb}</span>
                                        ) : (
                                            <>
                                                <span
                                                    onClick={() => handleBreadcrumbClick(index)}
                                                    style={{
                                                        cursor: 'pointer',
                                                        color: '#5bc0de', // Màu xanh giống nút tạo thư mục
                                                        textDecoration: 'none'
                                                    }}
                                                >
                                                    {crumb}
                                                </span>
                                                <span
                                                    style={{
                                                        marginLeft: '15px', // Tăng khoảng cách cho dấu >
                                                        marginRight: '5px', // Tăng khoảng cách cho dấu >
                                                        color: '#6c757d',
                                                        fontWeight: 'bold'
                                                    }}
                                                >
                                                    &gt;
                                                </span>
                                            </>
                                        )}
                                    </li>
                                ))}
                            </ol>
                            <style>
                                {`
                .breadcrumb-item::before {
                    display: none !important;
                }
                .breadcrumb-item {
                    padding-left: 0 !important;
                }
                .breadcrumb-item:hover span {
                    color: #31b0d5 !important; // Màu xanh đậm hơn khi hover
                }
            `}
                            </style>
                        </nav>
                    </div>

                    <div>
                        <Button color="info" onClick={handleCreateFolder} style={{ marginRight: '10px' }}>
                            <i className="fa fa-folder-plus"></i> Tạo thư mục
                        </Button>
                        <Input
                            type="file"
                            id="fileUpload"
                            multiple
                            style={{ display: 'none' }}
                            onChange={handleUpload}
                        />
                        <Button color="success" onClick={() => document.getElementById('fileUpload').click()}>
                            <i className="fa fa-upload"></i> Tải lên
                        </Button>
                    </div>
                </CardHeader>

                <CardBody style={{ minHeight: '500px', flex: 1, display: 'flex', flexDirection: 'column' }} onContextMenu={handleCardBodyContextMenu}  >
                    {loading ? (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '100%'
                            }}
                        >
                            <Spinner
                                color="info"
                                style={{
                                    width: '3rem',
                                    height: '3rem'
                                }}
                            >
                                Loading...
                            </Spinner>
                        </div>
                    ) : (
                        <Table className="table-hover" style={{ marginTop: '10px', lineHeight: '15px' }}>
                            <thead className="text-primary">
                                <tr>
                                    <th>Tệp tin</th>
                                    <th>Người tải lên</th>
                                    <th>Ngày tải lên</th>
                                    <th>Kích thước</th>
                                    <th>Xác thực</th>
                                    <th>Sao chép</th>
                                    <th>Link nhanh</th>
                                    <th>Tải xuống</th>
                                </tr>
                            </thead>
                            <tbody>
                                {files.map((file, index) => (
                                    <tr
                                        key={index}
                                        onDoubleClick={() => file.type === 'folder' && handleFolderClick(file.name)}
                                        onClick={(e) => handleFileClick(e, file.name)}
                                        onContextMenu={(e) => handleContextMenu(e, file.name)}
                                        style={{
                                            cursor: 'pointer',
                                            backgroundColor: selectedFiles.includes(file.name) ? '#63BCDC' : 'transparent',
                                            minHeight: '51px',
                                            height: '51px'
                                        }}
                                    >
                                        <td>
                                            {file.type === 'folder' ? (
                                                <i className="fa fa-folder" style={{ marginRight: '10px', color: 'goldenrod' }}></i>
                                            ) : (
                                                <i className="fa fa-file" style={{ marginRight: '10px', color: 'gray' }}></i>
                                            )}
                                            <span
                                                style={{
                                                    color: selectedFiles.includes(file.name) ? '#fff' : '#212529',
                                                    display: 'inline-block',
                                                    maxWidth: '320px',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    whiteSpace: 'nowrap',
                                                    verticalAlign: 'bottom'
                                                }}
                                                title={file.name} // Hiển thị tên đầy đủ khi hover
                                            >
                                                {file.name.length > 200
                                                    ? file.name.substring(0, 200) + '...'
                                                    : file.name}
                                            </span>
                                        </td>
                                        <td style={{ color: selectedFiles.includes(file.name) ? '#fff' : '#212529' }}>
                                            {file.uploadedBy || "--"}
                                        </td>
                                        <td style={{ color: selectedFiles.includes(file.name) ? '#fff' : '#212529' }}>
                                            {formatDate(file.updatedAt)}
                                        </td>
                                        <td style={{ color: selectedFiles.includes(file.name) ? '#fff' : '#212529' }}>
                                            {file.size ? formatSize(file.size) : "--"}
                                        </td>
                                        <td style={{ color: selectedFiles.includes(file.name) ? '#fff' : '#212529' }}>
                                            {file.type === 'file' && (
                                                <Input
                                                    type="select"
                                                    value={file.requireLoginTool || ''}
                                                    onChange={(e) => handleToolChange(file.name, e.target.value)}
                                                    style={{
                                                        width: '180px',
                                                        height: '32px',
                                                        marginTop: '-10px',
                                                        marginBottom: '-10px',
                                                    }}
                                                >
                                                    <option value="">Riêng tư</option>
                                                    <option value="public" style={{ height: '35px' }}>Công cộng</option>
                                                    {tools.map((tool) => (
                                                        <option key={tool.matool} value={tool.matool} style={{ height: '35px' }}>
                                                            {tool.name}
                                                        </option>
                                                    ))}
                                                </Input>
                                            )}
                                        </td>
                                        <td style={{ color: selectedFiles.includes(file.name) ? '#fff' : '#212529' }}>
                                            {(file.type === 'file' && file.requireLoginTool !== '') && (
                                                <Button color="success" size="sm" onClick={() => handleCopyUrlAuth(file.name, file.requireLoginTool)}  >
                                                    <i className="fa fa-copy" style={{ marginRight: "5px" }}></i>
                                                    Copy URL
                                                </Button>
                                            )}
                                        </td>
                                        <td style={{ color: selectedFiles.includes(file.name) ? '#fff' : '#212529' }}>
                                            {file.type === 'file' && (
                                                <Button className="mr-2" color="success" size="sm" onClick={() => handleCopyUrl(file.name, file.root)}  >
                                                    <i className="fa fa-share" style={{ marginRight: "5px" }}></i>
                                                    Link 1H
                                                </Button>
                                            )}
                                        </td>
                                        <td style={{ color: selectedFiles.includes(file.name) ? '#fff' : '#212529' }}>
                                            {file.type === 'file' && (
                                                <Button className="mr-2" color="success" size="sm" onClick={() => {
                                                    window.location.href = `${baseURL}/download/${file.root}${file.name}?token=${JSON.parse(Deco(localStorage.getItem('user'))).token}`;
                                                }}>
                                                    <i className="fa fa-download" style={{ marginRight: "5px" }}></i>
                                                    Tải xuống
                                                </Button>
                                            )}
                                        </td>
                                        <td style={{ color: selectedFiles.includes(file.name) ? '#fff' : '#212529' }}>
                                            <Button
                                                color="danger"
                                                size="sm"
                                                onClick={(e) => {
                                                    e.preventDefault(); // Ngăn chặn mọi sự kiện mặc định
                                                    e.stopPropagation(); // Ngăn chặn sự kiện lan truyền
                                                    const filesToDelete = selectedFiles.includes(file.name)
                                                        ? selectedFiles  // Nếu file đã được chọn, xóa tất cả các file đã chọn
                                                        : [file.name];  // Nếu file chưa được chọn, chỉ xóa file đó

                                                    // Gọi hàm xóa với danh sách file cần xóa
                                                    const currentPath = breadcrumb.slice(1).join("/") || "root";
                                                    Swal.fire({
                                                        title: 'Xác nhận xóa',
                                                        html: filesToDelete.length > 1
                                                            ? `Bạn có chắc chắn muốn xóa <b>${filesToDelete.length}</b> mục đã chọn?`
                                                            : `Bạn có chắc chắn muốn xóa <b>${filesToDelete[0]}</b>?`,
                                                        icon: 'warning',
                                                        showCancelButton: true,
                                                        confirmButtonColor: '#d33',
                                                        cancelButtonColor: '#3085d6',
                                                        confirmButtonText: 'Xóa',
                                                        cancelButtonText: 'Hủy'
                                                    }).then((result) => {
                                                        if (result.isConfirmed) {
                                                            DownloadFileService.deleteFiles(currentPath, filesToDelete)
                                                                .then(() => {
                                                                    Swal.fire(
                                                                        'Đã xóa!',
                                                                        filesToDelete.length > 1
                                                                            ? 'Các mục đã chọn đã được xóa.'
                                                                            : 'Mục đã chọn đã được xóa.',
                                                                        'success'
                                                                    );
                                                                    loadFiles(currentPath);
                                                                    setSelectedFiles([]);
                                                                })
                                                                .catch((error) => {
                                                                    Swal.fire(
                                                                        'Lỗi!',
                                                                        `Không thể xóa: ${error}`,
                                                                        'error'
                                                                    );
                                                                });
                                                        }
                                                    });
                                                }}
                                            >
                                                <i className="fa fa-trash" style={{ marginRight: "5px" }}></i>
                                                Xóa
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    )}

                    {contextMenu.visible && (
                        <div
                            style={{
                                position: 'fixed',
                                top: contextMenu.y,
                                left: contextMenu.x,
                                backgroundColor: 'white',
                                boxShadow: '0 2px 10px rgba(0,0,0,.1)',
                                borderRadius: '4px',
                                zIndex: 1000
                            }}
                        >
                            <DropdownMenu style={{ display: 'block', position: 'static' }}>
                                {/* Luôn hiển thị tùy chọn tạo thư mục mới */}
                                <DropdownItem onClick={handleCreateFolder}>
                                    <i className="fa fa-folder-plus mr-2"></i>
                                    Thư mục mới
                                </DropdownItem>

                                {/* Chỉ hiển thị tùy chọn xóa khi đang ở trên bảng và có file được chọn */}
                                {contextMenu.onTable && selectedFiles.length > 0 && (
                                    <DropdownItem onClick={handleDeleteFiles}>
                                        <i className="fa fa-trash mr-2"></i>
                                        {selectedFiles.length > 1
                                            ? `Xóa ${selectedFiles.length} mục đã chọn`
                                            : 'Xóa'}
                                    </DropdownItem>
                                )}
                            </DropdownMenu>
                        </div>
                    )}

                </CardBody>

                {/* Toast for upload progress */}
                <div style={{ position: 'fixed', bottom: '20px', right: '20px', zIndex: 1050 }}>
                    {uploadProgress.map((item, index) => (
                        <Toast key={index} isOpen={true} className="mb-2" style={{ minWidth: '300px' }}>
                            <ToastBody>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    marginBottom: '10px',
                                }}>
                                    <div style={{
                                        fontWeight: 'bold',
                                        maxWidth: '200px',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap'
                                    }}
                                        title={item.name} // Hiển thị tên đầy đủ khi hover
                                    >
                                        {item.name.length > 60
                                            ? item.name.substring(0, 60) + '...'
                                            : item.name}
                                    </div>
                                </div>
                                <Progress
                                    value={item.progress}
                                    style={{ height: '20px' }}
                                >
                                    <div style={{
                                        position: 'absolute',
                                        width: '100%',
                                        textAlign: 'center',
                                        color: item.progress > 40 ? 'white' : 'black',
                                        lineHeight: '20px'
                                    }}>
                                        {item.progress}%
                                    </div>
                                </Progress>
                            </ToastBody>
                        </Toast>
                    ))}
                </div>
            </Card>
        </div>
    );
}

export default DownloadFile;
