// 📄 CreateReportDetails.js
import React, { useState, useEffect } from 'react';
import {
  FormGroup, Label, Input, Button, Card, CardHeader, CardBody, CardFooter, Row, Col
} from 'reactstrap';
import Swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';

import reportService from 'services/report.service';
import authService from 'services/auth.service';
import toolService from 'services/tool.service';

const jwt = require('jsonwebtoken');

function CreateReportDetails({ onBack }) {
  const [errorData, setErrorData] = useState('');
  const [attachmentName, setAttachmentName] = useState('');
  const [attachmentFile, setAttachmentFile] = useState(null);
  const [members, setMembers] = useState([]);
  const [selectedMember, setSelectedMember] = useState(null);
  const [note, setNote] = useState('');
  const [status, setStatus] = useState('Pending');
  const [adminResponse, setAdminResponse] = useState('');
  const [tools, setTools] = useState([]);
  const [selectedTool, setSelectedTool] = useState('');
  const [stackTrace, setStackTrace] = useState('');
  const [images, setImages] = useState([]);
  const [isDragOver, setIsDragOver] = useState(false);

  // Thêm state để chặn double-click
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    fetchMembers();
    fetchTools();
  }, []);

  /** 📥 Lấy danh sách thành viên */
  function fetchMembers() {
    reportService.getAllUsers()
      .then(response => {
        setMembers(response);

        // Lấy thông tin user hiện tại đặt làm mặc định cho người xử lý
        const user = authService.getCurrentUserData();
        const decoded = jwt.decode(user.token, { complete: true });
        const selected = response.find(member => member.username === decoded.payload.username);
        setSelectedMember(selected || null);
      })
      .catch(error => {
        console.error("Lỗi khi lấy danh sách thành viên:", error);
      });
  }

  /** 📥 Lấy danh sách Tool */
  function fetchTools() {
    toolService.getToolDatabase()
      .then(response => {
        setTools(response.data);
      })
      .catch(error => {
        console.error("Lỗi khi lấy danh sách Tool:", error);
      });
  }

  /** 📤 Gửi báo cáo mới */
  const handleCreateReport = async () => {
    // Nếu bạn muốn kiểm tra field trống thì check trước:
    if (!selectedTool || !stackTrace || !errorData) {
      Swal.fire('Cảnh báo', 'Vui lòng điền đầy đủ thông tin trước khi tạo báo cáo.', 'warning');
      return;
    }

    // Tránh double-click
    if (isUploading) return;
    setIsUploading(true);

    // Hiển thị SweetAlert loading
    Swal.fire({
      title: 'Đang tạo báo cáo...',
      text: 'Xin vui lòng chờ trong giây lát...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const reportPayload = new FormData();
    reportPayload.append('toolName', selectedTool);
    reportPayload.append('stackTrace', stackTrace);
    reportPayload.append('errorData', errorData);
    reportPayload.append('attachment', attachmentFile);
    images.forEach(image => {
      reportPayload.append('imageAttachments', image);
    });
    reportPayload.append('handlerByUsername', selectedMember?.username || '');
    reportPayload.append('note', note);
    reportPayload.append('status', status);
    reportPayload.append('adminResponse', adminResponse);

    try {
      await reportService.createReport(reportPayload);
      // Tắt loading cũ và hiển thị thông báo thành công
      Swal.fire('Thành công!', 'Báo cáo đã được tạo thành công.', 'success');
      onBack();
    } catch (ex) {
      Swal.fire('Lỗi!', 'Không thể tạo báo cáo.', 'error');
    } finally {
      setIsUploading(false);
    }
  };

  /** 📷 Xử lý kéo-thả và Clipboard */
  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragOver(false);
    const files = Array.from(e.dataTransfer.files).filter(file => file.type.startsWith('image/'));
    if (files.length > 0) {
      setImages(prev => [...prev, ...files]);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragOver(true); // Hiệu ứng khi kéo vào
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragOver(false); // Tắt hiệu ứng khi chuột rời khỏi khung
  };

  const handlePaste = (e) => {
    const items = (e.clipboardData || window.clipboardData).items;
    const files = [];
    for (const item of items) {
      if (item.type.startsWith('image/')) {
        const blob = item.getAsFile();
        files.push(blob);
      }
    }
    setImages(prev => [...prev, ...files]);
  };

  /** 🗑️ Xóa hình ảnh */
  const handleRemoveImage = (index) => {
    setImages(images.filter((_, i) => i !== index));
  };

  return (
    <div className="content">
      <Row>
        <Col lg="9" md="8">
          <Card style={{ backgroundColor: '#fff', borderRadius: '10px', padding: '15px', margin: '20px auto' }}>
            <CardHeader>
              <h5>📄 Tạo Báo Lỗi Mới</h5>
            </CardHeader>
            <CardBody style={{ marginTop: '-15px' }}>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label>Tool Name</Label>
                    <Input
                      type="select"
                      value={selectedTool}
                      onChange={(e) => setSelectedTool(e.target.value)}
                    >
                      <option value="">-- Chọn Tool --</option>
                      {tools.map(tool => (
                        <option key={tool.id} value={tool.name}>
                          {tool.name}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label>Stack Trace</Label>
                    <Input
                      value={stackTrace}
                      onChange={(e) => setStackTrace(e.target.value)}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <FormGroup style={{ marginTop: '8px' }}>
                <Label>Data</Label>
                <Input
                  type="textarea"
                  value={errorData}
                  onChange={(e) => setErrorData(e.target.value)}
                  style={{ minHeight: '260px' }}
                />
              </FormGroup>

              {/* Khung Kéo Thả Hình Ảnh */}
              <FormGroup style={{ marginTop: '20px' }}>
                <Label>Images</Label>
                <div
                  onDrop={handleDrop}
                  onDragOver={handleDragOver}
                  onDragLeave={handleDragLeave}
                  onPaste={handlePaste}
                  style={{
                    border: isDragOver ? '2px dashed rgba(40, 167, 70, 0.5)' : '2px dashed #ccc',
                    backgroundColor: isDragOver ? '#e6ffe6' : '#fff',
                    padding: '20px',
                    textAlign: 'center',
                    width: '100%',
                    minHeight: '20px',
                    borderRadius: '8px',
                    overflow: 'hidden',
                    transition: 'all 0.3s ease-in-out'
                  }}
                >
                  <Label>📷 Kéo thả hình ảnh hoặc dán từ Clipboard</Label>
                  <div style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(auto-fill, minmax(120px, 1fr))',
                    gap: '10px',
                    marginTop: '10px',
                  }}>
                    {images.map((image, index) => (
                      <div
                        key={index}
                        style={{
                          position: 'relative',
                          border: '1px solid #ddd',
                          borderRadius: '8px',
                        }}
                      >
                        <img
                          src={URL.createObjectURL(image)}
                          alt={`preview-${index}`}
                          style={{ width: '100%', height: '100px' }}
                        />
                        <Button
                          size="sm"
                          color="danger"
                          style={{
                            position: 'absolute',
                            top: '5px',
                            right: '5px',
                            borderRadius: '50%',
                          }}
                          onClick={() => handleRemoveImage(index)}
                        >
                          ×
                        </Button>
                      </div>
                    ))}
                  </div>
                </div>
              </FormGroup>

              <FormGroup style={{ marginTop: '15px', display: 'flex', alignItems: 'center' }}>
                <div style={{ flex: 1 }}>
                  <Label>Attachment</Label>
                  <Input
                    type="text"
                    value={attachmentName}
                    placeholder="Tên tệp đính kèm"
                    readOnly
                  />
                </div>
                <div style={{ marginLeft: '10px', marginTop: '22px', alignSelf: 'flex-end' }}>
                  <Button
                    color="primary"
                    style={{ height: '38px' }}
                    onClick={() => document.getElementById('attachmentInput').click()}
                  >
                    Tải Lên
                  </Button>
                  <Input
                    type="file"
                    id="attachmentInput"
                    style={{ display: 'none' }}
                    onChange={(e) => {
                      if (e.target.files.length > 0) {
                        const file = e.target.files[0];
                        setAttachmentName(file.name); // Hiển thị tên tệp
                        setAttachmentFile(file); // Lưu đối tượng tệp để gửi lên máy chủ
                      }
                    }}
                  />
                </div>
              </FormGroup>
            </CardBody>
          </Card>
        </Col>

        <Col lg="3" md="4">
          {/* Bảng Nhận Việc */}
          <Card style={{ backgroundColor: '#fff', borderRadius: '10px', padding: '15px', margin: '20px auto' }}>
            <CardHeader>
              <h5>📥 Người thực hiện</h5>
            </CardHeader>
            <CardBody style={{ marginTop: '-15px' }}>
              <FormGroup>
                <Label>Người xử lý</Label>
                <Input
                  type="select"
                  value={selectedMember?.username || ''} // Hiển thị username nếu đã chọn
                  onChange={(e) => {
                    const selected = members.find(member => member.username === e.target.value);
                    setSelectedMember(selected || null);
                  }}
                >
                  {members.map(member => (
                    <option key={member.id} value={member.username}>
                      {member.fullname} ({member.username})
                    </option>
                  ))}
                </Input>
              </FormGroup>
              <FormGroup style={{ marginTop: '15px' }}>
                <Label>Trạng thái</Label>
                <Input
                  type="select"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <option value="Pending">Pending</option>
                  <option value="InProgress">InProgress</option>
                  <option value="Resolved">Resolved</option>
                  <option value="Ignored">Ignored</option>
                </Input>
              </FormGroup>
              <FormGroup style={{ marginTop: '15px' }}>
                <Label>Ghi chú từ người giao việc</Label>
                <Input
                  type="textarea"
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                  style={{ minHeight: '150px' }}
                />
              </FormGroup>

              <FormGroup style={{ marginTop: '15px' }}>
                <Label>Phản hồi cho người giao việc</Label>
                <Input
                  type="textarea"
                  value={adminResponse}
                  style={{ minHeight: '150px' }}
                  onChange={(e) => setAdminResponse(e.target.value)}
                />
              </FormGroup>
            </CardBody>

            <CardFooter style={{ textAlign: 'left', marginTop: '-10px', marginBottom: '10px' }}>
              <Button
                color="success"
                style={{ width: '100%' }}
                onClick={handleCreateReport}
                disabled={isUploading} // Khoá button khi đang upload
              >
                {isUploading ? 'Đang xử lý...' : 'Tạo Báo Lỗi'}
              </Button>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default CreateReportDetails;
