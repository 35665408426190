import axios from 'axios';
import authHeader from './auth-header';

const API_URL_V3 = "https://download.dhbtools.com";

// downloadFile.service.js
class DownloadFileService {
    getFiles(path) {
        const adjustedPath = path ? `${path}` : '';
        return axios.get(`${API_URL_V3}/files/list/${adjustedPath}`, {
            headers: authHeader()
        });
    }

    downloadFile(path, filename, options = {}) {
        const fullPath = path ? `${path}/${filename}` : filename;
        let url = `${API_URL_V3}/files/download/${fullPath}`;

        // Thêm query parameters nếu có
        if (options.token) url += `?token=${options.token}`;
        if (options.password) url += `?password=${options.password}`;
        // ... các options khác

        return url; // Trả về URL để redirect
    }

    generateShareToken(path, filename) {
        const fullPath = path ? `${path}/${filename}` : filename;
        return axios.get(`${API_URL_V3}/files/share-token/${fullPath}`, {
            headers: authHeader()
        });
    }

    createFolder(path, folderName) {
        const adjustedPath = path ? `${path}` : '';
        return axios.post(`${API_URL_V3}/files/create-folder/${adjustedPath}`,
            {
                data: folderName
            },
            {
                headers: authHeader()
            });
    }

    uploadFile(path, formData, onUploadProgress) {
        const adjustedPath = path ? `${path}` : '';
        return axios.post(`${API_URL_V3}/files/upload/${adjustedPath}`, formData, {
            headers: {
                ...authHeader(),
                'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: onUploadProgress // Thêm lại callback progress
        });
    }

    async uploadFileChunked(path, file, onUploadProgress) {
        const chunkSize = 50 * 1024 * 1024; // 50MB
        const totalChunks = Math.ceil(file.size / chunkSize);
        const adjustedPath = path ? `${path}` : '';
        let uploadedBytes = 0; // Biến lưu tổng số byte đã tải lên

        for (let i = 0; i < totalChunks; i++) {
            const chunk = file.slice(i * chunkSize, (i + 1) * chunkSize);

            const headers = {
                ...authHeader(),
                'Content-Type': 'application/octet-stream', // Chỉ định kiểu dữ liệu
                'x-file-name': file.name,
                'x-chunk-index': i,
                'x-total-chunks': totalChunks,
            };

            try {
                await axios.post(`${API_URL_V3}/files/upload/${adjustedPath}`, chunk, {
                    headers,
                    onUploadProgress: (progressEvent) => {
                        // Tính số byte đã tải trong chunk hiện tại
                        const chunkProgress = progressEvent.loaded;

                        // Tính tổng byte đã tải lên
                        const totalUploaded = uploadedBytes + chunkProgress;

                        // Gọi callback với tiến độ toàn bộ file
                        const totalProgress = Math.min(
                            Math.round((totalUploaded / file.size) * 100),
                            100
                        );

                        if (onUploadProgress) {
                            onUploadProgress({ loaded: totalUploaded, total: file.size, progress: totalProgress });
                        }
                    },
                });

                // Cập nhật tổng số byte đã tải lên sau khi hoàn thành chunk
                uploadedBytes += chunk.size;
            } catch (error) {
                console.error(`Error uploading chunk ${i + 1} of ${totalChunks}`, error);
                throw error; // Dừng upload nếu có lỗi
            }
        }

        console.log('File upload completed successfully.');
    }


    deleteFiles(path, items) {
        const adjustedPath = path ? `${path}` : '';
        return axios.delete(`${API_URL_V3}/files/delete/${adjustedPath}`, {
            headers: authHeader(),
            data: { items }
        });
    }

    getToolList() {
        return axios.get(`${API_URL_V3}/tools/list`, {
            headers: authHeader()
        });
    }

    updateFileRequireLoginTool(path, fileName, matool) {
        const adjustedPath = path ? `${path}` : '';
        return axios.put(`${API_URL_V3}/files/update-auth/${adjustedPath}`, {
            fileName,
            requireLoginTool: matool
        }, {
            headers: authHeader()
        });
    }
}

export default new DownloadFileService();